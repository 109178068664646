import axios from "axios";
import { server } from "./index";

export async function domainAuthSave(data, headers) {
  try {
    const response = await axios.post(`${server}/da/new`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateDomainAuthUrl(id, data, headers) {
  try {
    const response = await axios.patch(`${server}/da/update/${id}`, data, {
      headers: headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}
