import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import AddIcon from "@mui/icons-material/Add";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";

// import placeholder_img from "./../images/placeholder.png";
import spoe_logo from "./../images/spoelogo.png";
// validator
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";

// api
import { queryQuestion } from "../api/openAi";
import { registerUser } from "../api/user";

import { useDispatch } from "react-redux";
import { collectUserData } from "../redux/reducer/userAuth";

// typiging effect
import { TypingEffectArray } from "./../components/TypingEffect";

// router
import { useNavigate } from "react-router-dom";

// loader
import BounceLoader from "react-spinners/BounceLoader";

const initialState = {
  businessEmail: "",
  firstName: "",
  lastName: "",
  websiteUrl: "",
  companyEmail: "",
  password: "",
  companyName: "",
  companyOperation: "",
  companySellingPoints: "",
  companyIdealCustomer: "",
  currentMarketingEfforts: "",
  companyGoal: "",
  previousCampaigns: "",
};

const initalError = {
  businessEmail: false,
  firstName: false,
  lastName: false,
  websiteUrl: false,
  companyEmail: false,
  password: false,
  companyName: false,
  companyOperation: false,
  companySellingPoints: false,
  companyIdealCustomer: false,
  currentMarketingEfforts: false,
  companyGoal: false,
  previousCampaigns: false,
  budget: false,
  notEmail: "aa",
  notStrongPass: "",
};

const switchTexts = [
  { text: "Marketing Strategy with AI-Generated Ideas" },
  { text: "Campaigns with our AI Consultant" },
  { text: "Marketing Efforts with our AI Helper" },
  { text: "Business Growth with AI-Powered Solutions" },
  { text: "Creativity with AI-Generated Ideas" },
  { text: "Marketing Potential with AI-Powered Solutions" },
  { text: "Marketing Success with AI-Generated Ideas" },
  { text: "Marketing Efforts with AI's Full Potential" },
  { text: "Business with AI-Powered Marketing Ideas" },
  { text: "Marketing Game with AI-Generated Solutions" },
];

function OnBoarding(props) {
  const [counter, setCounter] = useState(0);
  const [values, setValues] = useState(initialState);
  const [budget, setBudget] = useState("");
  const [error, setError] = useState(initalError);
  // state suggestions
  const [nameAndUrl, setNameAndUrl] = useState("");
  const [aiCompanyOp, setAiCompanyOp] = useState("");
  const [sellingPoint, setSellingPoint] = useState([]);
  const [idealCustomer, setIdealCustomer] = useState([]);
  const [efforts, setEfforts] = useState([]);
  const [mainGoal, setMainGoal] = useState([]);
  const [addedPoints, setAddedPoints] = useState([]);
  const [addedIdealCustomers, setAddedIdealCustomers] = useState([]);
  const [addedMarketingEfforts, setAddedMarketingEfforts] = useState([]);
  const [addedCompanyGoals, setAddedCompanyGoals] = useState([]);
  const [isUSD, setIsUSD] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCounter, setErrorCounter] = useState(0);
  const [retry, setRetry] = useState(false);
  const smallScreen = useMediaQuery("(max-width:600px)");

  // const [prevEfforts, setPrevEfforts] = useState("");

  // currency
  const handleToggle = () => {
    setIsUSD(!isUSD);
    setBudget(`<${isUSD ? "£" : "$"}1000`);
  };

  // dispatch calls actions
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  //   handle budget chnage
  const handleBudgetChange = (event) => {
    setBudget(event.target.value);
    setError({ ...error, budget: false });
  };

  // handles textfield changes
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  // handles textfield errors
  const handleError = (prop) => {
    console.log("handle error");
    setError({
      ...error,
      [prop]: !error[prop],
    });
  };

  // show password
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // remove error on input flieds
  useEffect(() => {
    if (error.firstName === true) {
      setError({
        ...error,
        firstName: false,
      });
    }
    if (error.lastName === true) {
      setError({
        ...error,
        lastName: false,
      });
    }
    if (error.websiteUrl === true) {
      setError({
        ...error,
        websiteUrl: false,
      });
    }
    if (error.companyEmail === true) {
      setError({
        ...error,
        companyEmail: false,
      });
    }
    if (error.companyName === true) {
      setError({
        ...error,
        companyName: false,
      });
    }
    if (error.companyOperation === true) {
      setError({
        ...error,
        companyOperation: false,
      });
    }
    if (error.companySellingPoints === true) {
      setError({
        ...error,
        companySellingPoints: false,
      });
    }
    if (error.companyIdealCustomer === true) {
      setError({
        ...error,
        companyIdealCustomer: false,
      });
    }
    if (error.currentMarketingEfforts === true) {
      setError({
        ...error,
        currentMarketingEfforts: false,
      });
    }
    if (error.companyGoal === true) {
      setError({
        ...error,
        companyGoal: false,
      });
    }
    if (error.budget === true) {
      setError({
        ...error,
        budget: false,
      });
    }
    if (error.password === true) {
      setError({
        ...error,
        password: false,
      });
    }
    // eslint-disable-next-line
  }, [values, budget]);

  // first autofill
  const generateNameAndUrl = async () => {
    const data = {
      // do not show on chat, only added behind the scenes
      prompt: `Please only reply with the business name and nothing else for ${values.websiteUrl}. Don't add any new lines, just with plain text`,
      temperature: 1,
      max_tokens: 1000,
      top_p: 1,
      best_of: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };
    var headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    // set is loading to true
    setIsLoading(true);

    queryQuestion(data, headers, (callback) => {
      try {
        if (callback.status === 200) {
          setNameAndUrl(callback.data.choices[0].text.replace("\n\n", "\n"));

          console.log(callback);
        } else {
          setErrorCounter(errorCounter + 1);
          if (errorCounter < 2) {
            setRetry(true);
          } else if (errorCounter >= 2) {
            setRetry(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  // second autofill
  const generateCompanyOperation = async () => {
    const data = {
      prompt: `Please write in a concise and easy-to-understand sentence what ${values.websiteUrl}. Start the sentence with the company name`,
      temperature: 1,
      max_tokens: 1000,
      top_p: 1,
      best_of: 2,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };

    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    setIsLoading(true);

    queryQuestion(data, headers, (callback) => {
      try {
        if (callback.status === 200) {
          setAiCompanyOp(
            callback.data.choices[0].text
              .replace(".\n\n", "")
              .replace(":\n\n", "")
              .replace("\n\n", "")
          );
          console.log(callback);
        } else {
          console.log(callback);
          setErrorCounter(errorCounter + 1);
          if (errorCounter < 2) {
            setRetry(true);
          } else if (errorCounter >= 2) {
            setRetry(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  // third autofill
  const generateSellingPoints = async () => {
    const data = {
      prompt: `For ${values.websiteUrl}’s products or services please return three main selling points that are benefit focused for their target audience and store each in square brackets. Include any Unique Selling Points. To give you extra context, this is what the company does: ${values.companyOperation}. format the output as a JSON array, with each name being an object with a key of "selling_point"`,
      temperature: 1,
      max_tokens: 2500,
      top_p: 1,
      best_of: 2,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };

    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    setIsLoading(true);

    queryQuestion(data, headers, (callback) => {
      try {
        if (callback.status === 200) {
          const sellingPoints = JSON.parse(callback.data.choices[0].text);
          setSellingPoint(sellingPoints);
          console.log(sellingPoints);
        } else {
          console.log(callback);
          setErrorCounter(errorCounter + 1);
          if (errorCounter < 2) {
            setRetry(true);
          } else if (errorCounter >= 2) {
            setRetry(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  // fourth autofill
  const generateIdealCustomer = async () => {
    const data = {
      prompt: `For ${values.websiteUrl}, please return 3 ideal customer profiles and the pain point the product solves for each profile. Format the response as a JSON array, with each profile being represented as an object with a single key-value pair. The key should be "ideal_customer" and the value should be a string that includes the profile name, relevant demographic information, and the pain point. Make the response as concise as possible.`,
      temperature: 1,
      max_tokens: 2500,
      top_p: 1,
      best_of: 2,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };

    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    setIsLoading(true);

    queryQuestion(data, headers, (callback) => {
      try {
        if (callback.status === 200) {
          const idealCustomers = JSON.parse(callback.data.choices[0].text);
          setIdealCustomer(idealCustomers);
        } else {
          console.log(callback);
          setErrorCounter(errorCounter + 1);
          if (errorCounter < 2) {
            setRetry(true);
          } else if (errorCounter >= 2) {
            setRetry(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  // fifth autofill

  const generateMarketingEfforts = async () => {
    const data = {
      prompt: `Please reply with 3 potential digital marketing activities ${values.websiteUrl} could be running. Format the output as a JSON array, with each name being an object with a key of "marketing_effort".`,
      temperature: 1,
      max_tokens: 500,
      top_p: 1,
      best_of: 2,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };

    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    setIsLoading(true);

    queryQuestion(data, headers, (callback) => {
      try {
        if (callback.status === 200) {
          const marketing_effort = JSON.parse(callback.data.choices[0].text);
          setEfforts(marketing_effort);
          console.log(marketing_effort);
        } else {
          console.log(callback);
          setErrorCounter(errorCounter + 1);
          if (errorCounter < 2) {
            setRetry(true);
          } else if (errorCounter >= 2) {
            setRetry(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  // sixth autofill
  const generateGoals = async () => {
    const b2c = ["Purchases"];
    const b2b = ["Leads"];
    const isB2C = values.businessType === "B2C";
    const defaultGoal = isB2C ? b2c : b2b;

    const data = {
      prompt: `Reply only with the top 5 potential business goals ${values.companyName} has. Each reply should be as short as possible while still conveying what the goal is. By default, if the business is a B2C, one of the responses must be "Purchases". If the business is B2B, one of the responses must be "Leads". Format the output as a JSON array, with each name being an object with a key of "goal".`,
      temperature: 1,
      max_tokens: 500,
      top_p: 1,
      best_of: 2,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };

    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    setIsLoading(true);

    queryQuestion(data, headers, (callback) => {
      try {
        if (callback.status === 200) {
          const business_goal =
            JSON.parse(callback.data.choices[0].text) || defaultGoal;
          setMainGoal(business_goal);
          console.log(business_goal);
        } else {
          console.log(callback);
          setErrorCounter(errorCounter + 1);
          if (errorCounter < 2) {
            setRetry(true);
          } else if (errorCounter >= 2) {
            setRetry(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  // resets error coutner every on page change
  useEffect(() => {
    if (counter > 0) {
      setErrorCounter(0);
    }
  }, [counter]);

  // dynamically renders fields
  const renderFields = () => {
    switch (counter) {
      case 0:
        return (
          <>
            {" "}
            {/* title */}
            <div className="form-item">
              <Typography variant="h3">Get Started</Typography>
              <Typography variant="body1" style={{ marginRight: "20%" }}>
                Find out how SPOE can generate unique and tailored marketing
                ideas for your business.
              </Typography>
            </div>
            {/* first name */}
            <div className="form-item">
              <Typography variant="caption">First Name*</Typography>
              <TextField
                id="first-name"
                placeholder="First Name"
                variant="outlined"
                fullWidth
                onChange={handleChange("firstName")}
                value={values.firstName || ""}
                error={error.firstName}
                helperText={error.firstName ? "First name is requried" : ""}
              />
            </div>
            {/* last name */}
            <div className="form-item">
              <Typography variant="caption">Last Name</Typography>
              <TextField
                id="last-name"
                placeholder="Last Name"
                variant="outlined"
                fullWidth
                onChange={handleChange("lastName")}
                value={values.lastName || ""}
                error={error.lastName}
                helperText={error.lastName ? "Last name is requried" : ""}
              />
            </div>
            {/* Website Url */}
            <div className="form-item">
              <Typography variant="caption">Website URL</Typography>
              <TextField
                id="webiste-url"
                placeholder="Website URL"
                variant="outlined"
                fullWidth
                onChange={handleChange("websiteUrl")}
                value={values.websiteUrl || ""}
                error={error.websiteUrl}
                helperText={error.websiteUrl ? "Website URL is requried" : ""}
              />
            </div>
            {/* Work Email Address*/}
            <div className="form-item">
              <Typography variant="caption">Work Email Address</Typography>
              <TextField
                id="outlined-basic"
                placeholder="Work Email Address"
                variant="outlined"
                fullWidth
                onChange={handleChange("companyEmail")}
                value={values.companyEmail || ""}
                error={error.companyEmail}
                helperText={error.companyEmail ? "Not a valid email" : ``}
              />
            </div>
            {/* password */}
            <div className="form-item">
              <Typography
                variant="caption"
                display="block"
                style={{ marginTop: "10px" }}
              >
                Password
              </Typography>
              <TextField
                id="password-input-register"
                type={values.showPassword ? "text" : "password"}
                required
                error={error.password ? true : false}
                helperText={error.password ? "Password not strong enough" : ""}
                margin="normal"
                variant="outlined"
                fullWidth
                placeholder="*******"
                style={{ marginTop: "2px" }}
                onChange={handleChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={values.password || ""}
              />
            </div>
            {/* disclaimer */}
            <div className="form-item">
              <Typography variant="caption" style={{ marginRight: "20%" }}>
                By submitting this form, you agree to be contacted about SPOE's
                Products and Services. For more information, see our privacy
                policy
              </Typography>
            </div>
            <div className="form-item">
              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={() => {
                  if (!values.firstName) {
                    handleError("firstName");
                  } else if (!values.lastName) {
                    handleError("lastName");
                  } else if (!values.websiteUrl) {
                    handleError("websiteUrl");
                  } else if (!values.companyEmail) {
                    handleError("companyEmail");
                  } else {
                    console.log("All Fields filled");
                    // check if email is valid
                    if (!isEmail(values.companyEmail)) {
                      console.log("not an email");
                      setError({
                        ...error,
                        companyEmail: true,
                      });
                      handleError("companyEmail");
                      //   check if password is strong
                    } else if (!isStrongPassword(values.password)) {
                      console.log("not a strong password");
                      setError({
                        ...error,
                        password: true,
                      });
                      handleError("password");
                    } else {
                      generateNameAndUrl();
                      setCounter(counter + 1);
                    }
                  }
                }}
              >
                Start Your Free Trial
              </Button>
            </div>
            <div className="form-item">
              <Typography>
                Already have an account?{" "}
                <span
                  onClick={() => {
                    navigate({
                      pathname: "/login",
                    });
                    window.location.reload();
                  }}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  Sign In
                </span>
              </Typography>
            </div>
          </>
        );
      case 1:
        return (
          <>
            {/* What is your company name? */}
            <div className="form-item">
              <Typography variant="caption">
                What is your company name?*
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Company Name"
                variant="outlined"
                fullWidth
                onChange={handleChange("companyName")}
                value={values.companyName || ""}
                error={error.companyName}
                helperText={error.companyName ? "Company name is requried" : ""}
              />
            </div>
            {/* AI generated name suggestion */}
            {/*  nameAndUrl */}
            {/* set loading */}
            {isLoading === true ? (
              <div className="ai-loader">
                <BounceLoader
                  color={"#379FFF"}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  size={20}
                />
                {values.companyName === "" ? (
                  <Typography variant="caption" style={{ marginTop: "10px" }}>
                    Your company's AI is thinking...
                  </Typography>
                ) : (
                  <Typography variant="caption" style={{ marginTop: "10px" }}>
                    {values.companyName}'s AI is thinking...
                  </Typography>
                )}
              </div>
            ) : nameAndUrl !== "" ? (
              <div className="form-item generated">
                <Typography variant="caption">{nameAndUrl}</Typography>
                <div className="user-response">
                  <IconButton
                    className="good-output-onboarding"
                    size="small"
                    onClick={() => {
                      setValues({
                        ...values,
                        companyName: nameAndUrl,
                      });
                      console.log(values.companyName);
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  <IconButton className="bad-output-onboarding" size="small">
                    <ThumbDownOffAltIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            ) : retry === true ? (
              <>
                {/* retry button */}
                <div className="form-item">
                  <Button
                    onClick={() => {
                      generateNameAndUrl();
                    }}
                    fullWidth
                  >
                    Retry
                  </Button>
                </div>
              </>
            ) : (
              <>
                {/* if more than 2 retry */}
                <div className="form-item">
                  <Typography variant="body2">
                    Your AI hasn’t learnt enough about your business yet. Please
                    fill this answer manually
                  </Typography>
                </div>
              </>
            )}

            <div className="form-item">
              <Button
                variant="contained"
                fullWidth
                size="large"
                disabled={isLoading === true}
                onClick={() => {
                  console.log("Error Counter");
                  setErrorCounter(0);
                  if (!values.companyName) {
                    handleError("companyName");
                  } else {
                    // console.log("All Fields filled");
                    // generate company operation for next page asking about what the business does.
                    generateCompanyOperation();
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </Button>
            </div>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );
      case 2:
        return (
          <>
            {/* What is your company name? */}
            <div className="form-item">
              <Typography variant="h5">
                Let’s create the {values.companyName} Artificial Intelligence:
              </Typography>
              <Typography variant="body1">
                Write to your AI as if you are talking to a human.
              </Typography>
            </div>
            <div className="form-item">
              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={() => {
                  setCounter(counter + 1);
                  setErrorCounter(0);
                }}
              >
                Next
              </Button>
            </div>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setErrorCounter(0);
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );
      case 3:
        return (
          <>
            {/* What is your company name? */}
            <div className="form-item">
              <Typography variant="h5">
                In a sentence, what does your business do?
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Write in a concise and easy-to-understand sentence"
                variant="outlined"
                fullWidth
                multiline
                maxRows={3}
                onChange={handleChange("companyOperation")}
                value={values.companyOperation || ""}
                error={error.companyOperation}
                helperText={error.companyOperation ? "Requried field!" : ""}
              />
            </div>
            {isLoading === true ? (
              <div className="ai-loader">
                <BounceLoader
                  color={"#379FFF"}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  size={20}
                />
                {values.companyName === "" ? (
                  <Typography variant="caption" style={{ marginTop: "10px" }}>
                    Your company's AI is thinking...
                  </Typography>
                ) : (
                  <Typography variant="caption" style={{ marginTop: "10px" }}>
                    {values.companyName}'s AI is thinking...
                  </Typography>
                )}
              </div>
            ) : aiCompanyOp !== "" ? (
              <div className="form-item generated">
                <Typography variant="caption">{aiCompanyOp}</Typography>
                <div className="user-response">
                  <IconButton
                    className="good-output-onboarding"
                    size="small"
                    onClick={() => {
                      setValues({
                        ...values,
                        companyOperation: aiCompanyOp,
                      });
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  <IconButton className="bad-output-onboarding" size="small">
                    <ThumbDownOffAltIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            ) : retry === true ? (
              <>
                {/* retry button */}
                <div className="form-item">
                  <Button
                    onClick={() => {
                      generateCompanyOperation();
                    }}
                    fullWidth
                  >
                    Retry
                  </Button>
                </div>
              </>
            ) : (
              <>
                {/* if more than 2 retry */}
                <div className="form-item">
                  <Typography variant="body2">
                    Your AI hasn’t learnt enough about your business yet. Please
                    fill this answer manually.
                  </Typography>
                </div>
              </>
            )}

            <div className="form-item">
              <Button
                variant="contained"
                fullWidth
                size="large"
                disabled={isLoading === true}
                onClick={() => {
                  setErrorCounter(0);

                  if (!values.companyOperation) {
                    handleError("companyOperation");
                  } else {
                    console.log("All Fields filled");
                    // generate selling points for next page
                    generateSellingPoints();
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </Button>
            </div>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );
      case 4:
        return (
          <>
            {/*What are the main selling points of {companyName}’s products or services? */}
            <div className="form-item">
              <Typography variant="h5">
                What are the main selling points of {values.companyName}'s
                products or services?
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Please seperate the selling points by a coma(,)"
                variant="outlined"
                fullWidth
                multiline
                maxRows={3}
                onChange={handleChange("companySellingPoints")}
                value={values.companySellingPoints || ""}
                error={error.companySellingPoints}
                helperText={error.companySellingPoints ? "Requried field!" : ""}
              />
            </div>

            {/* manual */}
            {/* check if loading */}
            {isLoading ? (
              <>
                {" "}
                <div className="ai-loader">
                  <BounceLoader
                    color={"#379FFF"}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    size={20}
                  />
                  {values.companyName === "" ? (
                    <Typography variant="caption" style={{ marginTop: "10px" }}>
                      Your company's AI is thinking...
                    </Typography>
                  ) : (
                    <Typography variant="caption" style={{ marginTop: "10px" }}>
                      {values.companyName}'s AI is thinking...
                    </Typography>
                  )}
                </div>{" "}
              </>
            ) : // {/* check if arary is populated*/}
            sellingPoint.length > 0 ? (
              sellingPoint.map((point, index) => (
                <div key={index} className="form-item generated">
                  <Typography variant="caption">
                    {point.selling_point}
                  </Typography>
                  <div className="user-response">
                    {addedPoints.includes(point.selling_point) ? (
                      <IconButton
                        className="done-output-onboarding"
                        size="small"
                        disabled
                      >
                        <DoneOutlineIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="good-output-onboarding"
                        size="small"
                        disabled={addedPoints.includes(point.selling_point)}
                        onClick={() => {
                          setValues({
                            ...values,
                            companySellingPoints:
                              values.companySellingPoints.length > 0
                                ? values.companySellingPoints +
                                  ". " +
                                  point.selling_point
                                : point.selling_point,
                          });
                          setAddedPoints(
                            addedPoints.concat(point.selling_point)
                          );
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton className="bad-output-onboarding" size="small">
                      <ThumbDownOffAltIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : retry === true ? (
              <>
                {/* retry button */}
                <div className="form-item">
                  <Button
                    onClick={() => {
                      generateSellingPoints();
                    }}
                    fullWidth
                  >
                    Retry
                  </Button>
                </div>
              </>
            ) : (
              <>
                {/* if more than 2 retry */}
                <div className="form-item">
                  <Typography variant="body2">
                    Your AI hasn’t learnt enough about your business yet. Please
                    fill this answer manually.
                  </Typography>
                </div>
              </>
            )}

            <div className="form-item">
              <Button
                variant="contained"
                fullWidth
                size="large"
                disabled={isLoading === true}
                onClick={() => {
                  setErrorCounter(0);
                  if (!values.companySellingPoints) {
                    handleError("companySellingPoints");
                  } else {
                    console.log("All Fields filled");
                    // generate ideal customer for next page
                    generateIdealCustomer();
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </Button>
            </div>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );
      case 5:
        return (
          <>
            {/*Who are is your ideal customer profile? */}
            <div className="form-item">
              <Typography variant="h5">
                Please describe your ideal customer.
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Please describe your ideal customer."
                variant="outlined"
                fullWidth
                multiline
                maxRows={3}
                onChange={handleChange("companyIdealCustomer")}
                value={values.companyIdealCustomer || ""}
                error={error.companyIdealCustomer}
                helperText={error.companyIdealCustomer ? "Requried field!" : ""}
              />
            </div>

            {isLoading ? (
              <>
                {" "}
                <div className="ai-loader">
                  <BounceLoader
                    color={"#379FFF"}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    size={20}
                  />
                  {values.companyName === "" ? (
                    <Typography variant="caption" style={{ marginTop: "10px" }}>
                      Your company's AI is thinking...
                    </Typography>
                  ) : (
                    <Typography variant="caption" style={{ marginTop: "10px" }}>
                      {values.companyName}'s AI is thinking...
                    </Typography>
                  )}
                </div>{" "}
              </>
            ) : // {/* check if arary is populated*/}
            idealCustomer.length > 0 ? (
              idealCustomer.map((item, index) => {
                const addedCustomers = [...addedIdealCustomers];
                return (
                  <div key={index} className="form-item generated">
                    <Typography variant="caption">
                      {item["ideal_customer"]}
                    </Typography>
                    <div className="user-response">
                      {addedCustomers.includes(item.ideal_customer) ? (
                        <IconButton
                          className="done-output-onboarding"
                          size="small"
                          disabled
                        >
                          <DoneOutlineIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          className="good-output-onboarding"
                          size="small"
                          onClick={() => {
                            console.log(addedCustomers, item.ideal_customer);
                            setValues({
                              ...values,
                              companyIdealCustomer:
                                values.companyIdealCustomer.length > 0
                                  ? values.companyIdealCustomer +
                                    ". " +
                                    item.ideal_customer
                                  : item.ideal_customer,
                            });
                            setAddedIdealCustomers(
                              addedCustomers.concat(item.ideal_customer)
                            );
                          }}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      )}
                      <IconButton
                        className="bad-output-onboarding"
                        size="small"
                      >
                        <ThumbDownOffAltIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            ) : retry === true ? (
              <>
                {/* retry button */}
                <div className="form-item">
                  <Button
                    onClick={() => {
                      generateIdealCustomer();
                    }}
                    fullWidth
                  >
                    Retry
                  </Button>
                </div>
              </>
            ) : (
              <>
                {/* if more than 2 retry */}
                <div className="form-item">
                  <Typography variant="body2">
                    Your AI hasn’t learnt enough about your business yet. Please
                    fill this answer manually.
                  </Typography>
                </div>
              </>
            )}

            <div className="form-item">
              <Button
                variant="contained"
                fullWidth
                size="large"
                disabled={isLoading === true}
                onClick={() => {
                  setErrorCounter(0);
                  if (!values.companyIdealCustomer) {
                    handleError("companyIdealCustomer");
                  } else {
                    console.log("All Fields filled");
                    // generate potential marketing efforts and display on next page
                    generateMarketingEfforts();
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </Button>
            </div>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );
      case 6:
        return (
          <>
            {/* What are your current marketing efforts? *needs to be a list* */}
            <div className="form-item">
              <Typography variant="h5">
                What are your current marketing efforts?
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Please seperate marketing efforts by a coma(,)"
                variant="outlined"
                fullWidth
                multiline
                maxRows={3}
                onChange={handleChange("currentMarketingEfforts")}
                value={values.currentMarketingEfforts || ""}
                error={error.currentMarketingEfforts}
                helperText={
                  error.currentMarketingEfforts ? "Requried field!" : ""
                }
              />
            </div>
            {/* display potential marketing efforts */}

            {isLoading ? (
              <>
                {" "}
                <div className="ai-loader">
                  <BounceLoader
                    color={"#379FFF"}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    size={20}
                  />
                  {values.companyName === "" ? (
                    <Typography variant="caption" style={{ marginTop: "10px" }}>
                      Your company's AI is thinking...
                    </Typography>
                  ) : (
                    <Typography variant="caption" style={{ marginTop: "10px" }}>
                      {values.companyName}'s AI is thinking...
                    </Typography>
                  )}
                </div>{" "}
              </>
            ) : // {/* check if arary is populated*/}
            efforts.length > 0 ? (
              efforts.map((item, index) => (
                <div key={index} className="form-item generated">
                  <Typography variant="caption">
                    {item.marketing_effort}
                  </Typography>
                  <div className="user-response">
                    {addedMarketingEfforts.includes(item.marketing_effort) ? (
                      <IconButton
                        className="done-output-onboarding"
                        size="small"
                        disabled
                      >
                        <DoneOutlineIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="good-output-onboarding"
                        size="small"
                        onClick={() => {
                          console.log("Hello");
                          setValues({
                            ...values,
                            currentMarketingEfforts:
                              values.currentMarketingEfforts.length > 0
                                ? values.currentMarketingEfforts +
                                  ". " +
                                  item.marketing_effort
                                : item.marketing_effort,
                          });

                          setAddedMarketingEfforts(
                            addedMarketingEfforts.concat(item.marketing_effort)
                          );
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton className="bad-output-onboarding" size="small">
                      <ThumbDownOffAltIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : retry === true ? (
              <>
                {/* retry button */}
                <div className="form-item">
                  <Button
                    onClick={() => {
                      generateMarketingEfforts();
                    }}
                    fullWidth
                  >
                    Retry
                  </Button>
                </div>
              </>
            ) : (
              <>
                {/* if more than 2 retry */}
                <div className="form-item">
                  <Typography variant="body2">
                    Your AI hasn’t learnt enough about your business yet. Please
                    fill this answer manually.
                  </Typography>
                </div>
              </>
            )}

            <div className="form-item">
              <Button
                variant="contained"
                fullWidth
                disabled={isLoading === true}
                size="large"
                onClick={() => {
                  setErrorCounter(0);
                  if (!values.currentMarketingEfforts) {
                    handleError("currentMarketingEfforts");
                  } else {
                    console.log("All Fields required");
                    // generate suggested goals and display on next
                    generateGoals();
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </Button>
            </div>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );
      case 7:
        return (
          <>
            {/* What is the main goal you want to achieve? */}
            <div className="form-item">
              <Typography variant="h5">
                What is the main goal you want to achieve?
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Please seperate business goals by a coma(,)"
                variant="outlined"
                fullWidth
                multiline
                onChange={handleChange("companyGoal")}
                value={values.companyGoal || ""}
                error={error.companyGoal}
                helperText={error.companyGoal ? "All Fields required" : ""}
              />
            </div>

            {isLoading ? (
              <>
                {" "}
                <div className="ai-loader">
                  <BounceLoader
                    color={"#379FFF"}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    size={20}
                  />
                  {values.companyName === "" ? (
                    <Typography variant="caption" style={{ marginTop: "10px" }}>
                      Your company's AI is thinking...
                    </Typography>
                  ) : (
                    <Typography variant="caption" style={{ marginTop: "10px" }}>
                      {values.companyName}'s AI is thinking...
                    </Typography>
                  )}
                </div>{" "}
              </>
            ) : // {/* check if arary is populated*/}
            mainGoal.length > 0 ? (
              mainGoal.map((item, index) => (
                <div key={index} className="form-item generated">
                  <Typography variant="caption">{item.goal}</Typography>
                  <div className="user-response">
                    {addedCompanyGoals.includes(item.goal) ? (
                      <IconButton
                        className="done-output-onboarding"
                        size="small"
                        disabled
                      >
                        <DoneOutlineIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="good-output-onboarding"
                        size="small"
                        onClick={() => {
                          setValues({
                            ...values,
                            companyGoal:
                              values.companyGoal.length > 0
                                ? values.companyGoal + ". " + item.goal
                                : item.goal,
                          });
                          setAddedCompanyGoals(
                            addedCompanyGoals.concat(item.goal)
                          );
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton className="bad-output-onboarding" size="small">
                      <ThumbDownOffAltIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              ))
            ) : retry === true ? (
              <>
                {/* retry button */}
                <div className="form-item">
                  <Button
                    onClick={() => {
                      generateGoals();
                    }}
                    fullWidth
                  >
                    Retry
                  </Button>
                </div>
              </>
            ) : (
              <>
                {/* if more than 2 retry */}
                <div className="form-item">
                  <Typography variant="body2">
                    Your AI hasn’t learnt enough about your business yet. Please
                    fill this answer manually.
                  </Typography>
                </div>
              </>
            )}

            <div className="form-item">
              <Button
                variant="contained"
                fullWidth
                size="large"
                disabled={isLoading === true}
                onClick={() => {
                  setErrorCounter(0);
                  if (!values.companyGoal) {
                    handleError("companyGoal");
                  } else {
                    console.log("All fields filled");
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </Button>
            </div>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );
      case 8:
        return (
          <>
            {/* What is the main goal you want to achieve? */}
            <div className="form-item">
              <Typography variant="h5">What is your monthly budget?</Typography>
              <Typography variant="subtitle2">
                This is important, so we can train your AI to provide
                suggestions that align with CompanyName’s budget, whether it's a
                low-cost or high-cost strategy.
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={isUSD}
                    onChange={handleToggle}
                    name="currency-toggle"
                    color="primary"
                  />
                }
                label={isUSD ? "Currecy is: $" : "Currecy is: £"}
              />
              <FormControl
                fullWidth
                error={error.budget}
                style={{
                  marginTop: "20px",
                }}
              >
                <InputLabel id="select-budget">Select Budget</InputLabel>
                <Select
                  label={budget ? "Select Budget" : budget}
                  onChange={handleBudgetChange}
                  value={budget}
                >
                  <MenuItem value={`<${isUSD ? "$" : "£"}1000`}>
                    {" "}
                    {`<${isUSD ? "$" : "£"}1000`}
                  </MenuItem>
                  <MenuItem
                    value={`${isUSD ? "$" : "£"}1,000-${
                      isUSD ? "$" : "£"
                    }5,000`}
                  >{`${isUSD ? "$" : "£"}1,000-${
                    isUSD ? "$" : "£"
                  }5,000`}</MenuItem>
                  <MenuItem
                    value={`${isUSD ? "$" : "£"}5,000-${
                      isUSD ? "$" : "£"
                    }15,000`}
                  >{`${isUSD ? "$" : "£"}5,000-${
                    isUSD ? "$" : "£"
                  }15,000`}</MenuItem>
                  <MenuItem value={`>${isUSD ? "$" : "£"}15,000`}>{`>${
                    isUSD ? "$" : "£"
                  }15,000`}</MenuItem>
                </Select>
                <FormHelperText>
                  {error.budget ? "Budget is required!" : ""}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="form-item">
              <Button variant="contained" fullWidth size="large" type="submit">
                Submit
              </Button>
            </div>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="form-item">
              <Typography
                align="center"
                className="back-button-onboarding"
                variant="body2"
                onClick={() => {
                  setCounter(counter - 1);
                }}
              >
                Back
              </Typography>
            </div>
          </>
        );
    }
  };

  // handle user registration
  const handleRegisterCallback = async (inputValues) => {
    // set loading to true
    // set data
    // data names are based on input value dataa name
    const data = {
      email: inputValues.email,
      password: inputValues.password,
      first_name: inputValues.first_name,
      last_name: inputValues.last_name,
      company_name: inputValues.company_name,
      company_operation: inputValues.company_operation,
      company_selling_points: inputValues.company_selling_points,
      company_ideal_customer: inputValues.company_ideal_customer,
      company_goal: inputValues.company_goal,
      previous_campaigns: inputValues.previous_campaigns,
      current_marketing_efforts: inputValues.current_marketing_efforts,
      website_url: inputValues.website_url,
    };
    // content type headers
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await registerUser(data, headers);
      console.log(response);
      const userData = response.data;
      console.log(userData);
      const userInfo = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        token: userData.token,
        id: userData.id,
        tokenCounter: userData.tokenCounter,
        requestCounter: userData.requestCounter,
        isPremium: userData.isPremium,
        website_url: userData.website_url,
        company_operation: userData.company_operation,
        company_selling_points: userData.company_selling_points,
        company_ideal_customer: userData.company_ideal_customer,
        company_goal: userData.company_goal,
        previous_campaigns: userData.previous_campaigns,
        current_marketing_efforts: userData.current_marketing_efforts,
        company_name: userData.company_name,
      };

      // disptach to redux
      dispatch(collectUserData(userInfo));
      // add to local storage
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("user-data", JSON.stringify(userInfo));
      navigate({
        pathname: "/dashboard",
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
      setValues({ ...values, emailInUse: data.error });
    }
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} className="temp-border">
          <div className="form-container">
            <form
              className="form-styles"
              onSubmit={(e) => {
                e.preventDefault();

                const inputValues = {
                  email: values.companyEmail,
                  password: values.password,
                  first_name: values.firstName,
                  last_name: values.lastName,
                  company_name: values.companyName,
                  company_operation: values.companyOperation,
                  company_selling_points: values.companySellingPoints,
                  company_ideal_customer: values.companyIdealCustomer,
                  company_goal: values.companyGoal,
                  previous_campaigns: values.previousCampaigns,
                  current_marketing_efforts: values.currentMarketingEfforts,
                  website_url: values.websiteUrl,
                };

                console.log(inputValues);
                handleRegisterCallback(inputValues);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              {renderFields()}
            </form>
          </div>
        </Grid>
        {!smallScreen ? (
          <Grid
            item
            xs={12}
            md={9}
            className="temp-border"
            style={{ backgroundColor: "#D3DEFF", color: "#0c2b54" }}
          >
            <div className="onboarding-static">
              <div className="onboarding-hero-text">
                <img
                  src={spoe_logo}
                  alt="spoe logo"
                  className="logo-spoe-click"
                />
                <Typography
                  variant="h3"
                  style={{ marginLeft: "20px" }}
                  onClick={() => console.log(addedIdealCustomers)}
                >
                  Second Pair of Eyes
                </Typography>
              </div>
              <div className="text-onbaording-right">
                <Typography variant="h3">
                  <span style={{ fontWeight: "bold" }}>Transform your: </span>{" "}
                  <span style={{ display: "inline-block" }}>
                    <TypingEffectArray switchTexts={switchTexts} />
                  </span>
                </Typography>
              </div>
            </div>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
}

export default OnBoarding;
