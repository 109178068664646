import React, { useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

//logo
import spoe_logo from "./../images/spoelogo.png";

// validator
import isEmail from "validator/lib/isEmail";

const initialState = {
  recoveryEmail: "",
  notEmail: false,
};

function ForgetPasswordPage(props) {
  const [values, setValues] = useState(initialState);
  // handles changes
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value.trim(),
    });
  };

  // navigate
  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        height="95vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#FFFFF"
        style={{ marginTop: "5vh" }}
      >
        <Grid xs={12} md={4} className="forget-password-grid">
          <div className="logo-container">
            <img src={spoe_logo} alt="logo" />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // check if email
              if (!isEmail(values.recoveryEmail)) {
                // set error to true
                setValues({ ...values, notEmail: !values.notEmail });
                return console.log("Not valid email");
              } else {
                // set error to false
                setValues({ ...values, notEmail: false.notEmail });
              }
              console.log(values.recoveryEmail);
            }}
          >
            <TextField
              required
              margin="normal"
              variant="outlined"
              disabled
              fullWidth
              placeholder="youremail@spoe.co"
              onChange={handleChange("recoveryEmail")}
              value={values.recoveryEmail ? values.recoveryEmail : ""}
              error={values.notEmail ? true : false}
              helperText={
                values.notEmail ? "Not a valid email" : "Under development"
              }
            />
            <div className="submit-button">
              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                  paddingTop: "8px",
                  // background: "#379FFF",
                  color: "white",
                }}
                fullWidth
                type="submit"
                disabled
              >
                Submit
              </Button>
            </div>
          </form>
          <div
            style={{
              textAlign: "center",
              marginTop: "2em",
              color: "#379FFF",
            }}
            onClick={() => {
              // move forget password page
              navigate({
                pathname: "/login",
              });
            }}
          >
            <span type="submit" className="forgot-password-text">
              Back to login
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgetPasswordPage;
