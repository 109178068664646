export function checkUserAuth() {
  const user = JSON.parse(localStorage.getItem("user-data"));
  if (!user) {
    return false;
  }
  // check if there is user email in localstorage
  const isLoggedIn = user.email.includes("");
  if (!isLoggedIn) {
    return false;
  } else {
    return true;
  }
}

export function getUserDataSession() {
  const user = localStorage.getItem("user-data");

  if (user === null || user === undefined) {
    return false;
  } else {
    return JSON.parse(user);
  }
}
