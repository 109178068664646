import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  email: "",
  token: "",
  first_name: "",
  last_name: "",
};

export const userAuth = createSlice({
  name: "userauth",
  initialState,
  reducers: {
    // action types
    // user login
    collectUserData: (state, action) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
    },
  },
});

// Action creators are generated for each case reducer function
export const { collectUserData } = userAuth.actions;

export default userAuth.reducer;
