import React, { useState, useEffect } from "react";

// route for data passing / navigating
import { createSearchParams, useNavigate } from "react-router-dom";

//mui
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

// apis
import { submitUrl } from "../api/urls";

// reCaptcha for submit button
import ReCAPTCHA from "react-google-recaptcha";

// loader
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

// for metadata
import { Helmet } from "react-helmet-async";

// logo
import spoe_logo from "./../images/SPOE-logo-bdg-2000px.png";

// api
import { domainAuthSave } from "../api/domainAuth";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    submitButton: {
      // purple
      main: "#379FFF",
      contrastText: "#fff",
    },
  },
});

function Home(props) {
  // state
  const [urlValue, setUrlValue] = useState("");
  // settings initial daScore as negative because lowest possible daScore is 0
  const [daScore, setDaScore] = useState(-1);
  const [title, setTitle] = useState(" ");
  const [backlinks, setBacklinks] = useState(0);
  const [query, setQuery] = useState("");
  const [spam_score, setSpamScore] = useState(0);
  const [queryId, setQueryId] = useState("");

  // tooltip state
  const [open, setOpen] = useState(false);
  // recaptcha state
  const [verfied, setVerified] = useState(false);
  // set loading
  const [loading, setLoading] = useState(false);

  // navigate
  const navigate = useNavigate();

  // move to da with data from api
  const handleNavigate = (daScore, title) => {
    navigate({
      pathname: "/da",
      search: createSearchParams({
        daScore: daScore,
        title: title,
        queryId: queryId,
      }).toString(),
    });
  };

  // capture url change
  const onChangeUrl = (e) => {
    setUrlValue(e.target.value);
  };

  // submit url
  const handleSubmitUrl = (e) => {
    e.preventDefault();
    // opens tool tip for recaptcha
    setOpen((prevState) => (prevState = true));
  };

  useEffect(() => {
    const data = urlValue;
    // check if recpatch is good
    if (verfied === true) {
      submitUrl(data, (callback) => {
        console.log("fired");
        if (callback.status === 200) {
          setDaScore((prevState) => (prevState = callback.data.body.da_score));
          setBacklinks(
            (prevState) => (prevState = callback.data.body.total_backlink)
          );
          setQuery((prevState) => (prevState = callback.data.body.target));
          // check if spam score from api is zero since we base of -1
          if (callback.data.body.spam_score >= 0) {
            setSpamScore(
              (prevState) => (prevState = callback.data.body.spam_score)
            );
          } else {
            setSpamScore(
              (prevState) => (prevState = callback.data.body.spam_score + 1)
            );
          }

          // some urls return blank string
          if (callback.data.body.title !== "") {
            // if title exists
            setTitle((prevState) => (prevState = callback.data.body.title));
          } else {
            // if title doesn't exist use query instead
            setTitle((prevState) => (prevState = callback.data.body.target));
          }
        } else {
          console.log(callback);
        }
        // console.log(callback.data.body);
      });
    }

    // eslint-disable-next-line
  }, [verfied]);

  // runs when daScore is updated moves api data to da page
  useEffect(() => {
    const currentDate = new Date();

    // lowest possible daScore is 0
    if (daScore >= 0) {
      // set loading true
      setLoading((prevState) => (prevState = true));
      // console.log(daScore);

      const data = {
        backlinks: backlinks,
        da: daScore,
        query: query,
        spam_score: spam_score,
        title: title,
        date: currentDate,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      const saveQuery = async () => {
        try {
          const response = await domainAuthSave(data, headers);
          console.log(response.data._id);
          setQueryId(response.data._id);
        } catch (error) {
          console.log(error);
        }
      };
      saveQuery();
    }
    // Only using this because we're using daScore as focal point
    // eslint-disable-next-line
  }, [daScore]);

  useEffect(() => {
    if (queryId !== "") {
      setTimeout(() => {
        // sends parameters to da page
        setLoading((prevState) => (prevState = false));
        handleNavigate(daScore, title, queryId);
      }, 3000);
    }

    // eslint-disable-next-line
  }, [queryId]);

  // runs when tooltip is toggled
  // useEffect(() => {
  //   console.log(open);
  // }, [open]);

  // reCaptcha func
  const onVerify = (value) => {
    setVerified((prevState) => (prevState = true));
  };

  return (
    <>
      <Helmet>
        <title>
          See how Google rates your website | SPOE: Second Pair of Eyes
        </title>
        <meta
          name="description"
          content="Enter your website address to see a rating from 0 to 100 of how highly Google rate your website compared to others in your industry. The higher the ranking, the easier you will rank on Google. You will also see the number of websites that mention your websites in total."
        />
        <meta
          name="keywords"
          content=" domain authority, backlinks, how does google rate my website, how does google see my website"
        />
      </Helmet>
      <Grid
        container
        height="100vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#FFFFF"
      >
        {loading ? (
          <ClimbingBoxLoader
            color={"#379FFF"}
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
            size={20}
          />
        ) : (
          <Grid xs={10} md={8} lg={6} className="hero-container">
            <div className="logo-container">
              <img className="large-logo" src={spoe_logo} alt="logo" />
            </div>
            <div className="tagline_hero">
              <h1>Check Your Website Now</h1>
            </div>
            <div className="hero-text">
              <h1>SEE HOW GOOGLE SEES YOUR WEBSITE</h1>
            </div>
            <div className="enter-url">
              <h3>ENTER YOUR URL</h3>
              <TextField
                placeholder="www.yoursite.com"
                variant="outlined"
                fullWidth
                name="urlValue"
                value={urlValue}
                onChange={onChangeUrl}
                autoFocus
              />
              <ThemeProvider theme={theme}>
                <Tooltip
                  open={open}
                  placement="top-end"
                  title={
                    <div>
                      <ReCAPTCHA
                        // official
                        sitekey="6LehB7oiAAAAADFssF-TceGSs-tg4vO_fMRy6L_f"
                        // for testing
                        // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                        onChange={onVerify}
                      />
                    </div>
                  }
                >
                  <Button
                    variant="contained"
                    color="submitButton"
                    className="submit-button"
                    size="large"
                    onClick={handleSubmitUrl}
                    disabled={!urlValue}
                  >
                    SUBMIT
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Home;
