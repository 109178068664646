import axios from "axios";
import { server } from "./index";

export async function querySave(data, headers) {
  try {
    const response = await axios.post(`${server}/query/save`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}
