import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";

// pages
import Home from "./pages/Home.js";
import DomainAuth from "./pages/DomainAuth.js";
import { HelmetProvider } from "react-helmet-async";
import LoginPage from "./pages/loginPage";
import SignupPage from "./pages/signupPage";
import ForgetPasswordPage from "./pages/forgetPasswordPage";
import MarketingConsultancy from "./pages/marketingConsultancy";
// import Beta from "./pages/betaPage";
import OnBoarding from "./pages/onBoarding";
import Dashboard from "./pages/dashboard";
import PressRelease from "./pages/PressRelease";

import "@fontsource/sarabun"; // Defaults to weight 400.

// headers
import Header from "./components/layouts/headers";
import Sidebar from "./components/layouts/sidebar";

// helper
import { checkUserAuth } from "./helpers/userAuthHelper";

function App() {
  const isAuth = checkUserAuth();

  return (
    <>
      {/* hide header on these pages otherwise show */}
      {window.location.pathname === "/onboarding" ||
      window.location.pathname === "/dashboard" ||
      window.location.pathname === "/domain-checker" ||
      window.location.pathname === "/da" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/press-release" ? (
        ""
      ) : (
        <Header />
      )}
      {/* only sidebar on these pages */}
      {window.location.pathname === "/dashboard" ||
      window.location.pathname === "/press-release" ? (
        <Sidebar />
      ) : (
        ""
      )}

      <HelmetProvider>
        <Routes>
          <Route exact path="/domain-checker" element={<Home />} />
          <Route exact path="/" element={<OnBoarding />} />
          <Route exact path="/da" element={<DomainAuth />} />
          <Route
            exact
            path="/login"
            element={
              isAuth ? <Navigate to="/dashboard" replace /> : <LoginPage />
            }
          />
          <Route
            exact
            path="/register"
            element={isAuth ? <Navigate to="/" replace /> : <SignupPage />}
          />
          <Route exact path="/forget" element={<ForgetPasswordPage />} />
          <Route
            exact
            path="/press-release-writeup"
            element={
              !isAuth ? (
                <Navigate to="/register" replace />
              ) : (
                <MarketingConsultancy />
              )
            }
          />
          <Route exact path="/onboarding" element={<OnBoarding />} />
          {/* dashboard pages, pages below are where you show sidebar */}
          <Route
            exact
            path="/dashboard"
            element={!isAuth ? <Navigate to="/" replace /> : <Dashboard />}
          />
          <Route exact path="/press-release" element={<PressRelease />} />
        </Routes>
      </HelmetProvider>
    </>
  );
}

export default App;
