import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

// api
import { stripePay } from "./../api/stripe";
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const PaymentForm = (props) => {
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  // checks if user chose monthly or annually
  const isMonthly = props.amount_due === 49;

  const handleClick = () => {
    props.handleData(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // local storage data
    const user = JSON.parse(localStorage.getItem("user-data"));
    const user_email = user ? user.email : "";

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      // billing details
      billing_details: {
        email: user_email,
      },
    });

    if (result.error) {
      console.log(result.error);
    } else {
      const headers = { "Content-Type": "application/json" };
      const res = await stripePay(
        {
          // data sent to backend
          payment_method: result.paymentMethod.id,
          email: user_email,
          plan: isMonthly,
        },
        headers
      );

      console.log(res);
      // const res = await axios.post(
      //   "http://localhost:4000/stripe/customer/new",
      //   {
      //     // data sent to backend
      //     payment_method: result.paymentMethod.id,
      //     email: user_email,
      //     plan: isMonthly,
      //   }
      // );

      const { clientSecret, status } = res.data;

      if (status === "requires_action") {
        stripe.confirmPayment(clientSecret).then(function (result) {
          if (result.error) {
            console.log(result);
            console.log("There was an error");
          } else {
            console.log("You got the money");
            setSuccess(true);
            handleClick();
          }
        });
      } else {
        console.log("Money Received");
        setSuccess(true);
        handleClick();
        // No additional information was needed
        // Show a success message to your customer
        // setPaymentSuccess to true in parent
      }
    }
  };

  return (
    <>
      {!success ? (
        <form onSubmit={handleSubmit}>
          <fieldset className="FormGroup">
            <div className="FormRow">
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>
          <div className="secure-payment">
            <LockIcon />
            <span>Secure Payment via Stripe</span>
          </div>

          <button className="stripeButton">Pay</button>
        </form>
      ) : (
        <div>
          <h2>Success!</h2>
        </div>
      )}
    </>
  );
};

export default PaymentForm;

// const { error, paymentMethod } = await stripe.createPaymentMethod({
//   type: "card",
//   card: elements.getElement(CardElement),
//   // billing details
//   billing_details: {
//     email: "miguel@spoe.co",
//   },
// });

// if (!error) {
//   try {
//     const { id } = paymentMethod;
//     // const response = await axios.post("http://localhost:4000/stripe/pay", {
//     //   amount: 49000,
//     //   id,
//     // });
//     const response = await stripePay({
//       amount: amount,
//       id,
//     });
//     if (response.data.success) {
//       console.log("Successful Payment");
//       setSuccess(true);
//     }
//   } catch (error) {
//     console.log("Error", error);
//   }
// } else {
//   console.log(error.message);
//   return error.message;
// }
