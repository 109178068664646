import axios from "axios";

// Param: data
export async function queryQuestion(data, headers, callback) {
  await axios
    .post(
      `https://api.openai.com/v1/engines/text-davinci-003/completions`,
      data,
      {
        headers: headers,
      }
    )
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}
