import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate } from "react-router-dom";

function DrawerMenu() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <IconButton aria-label="menu" onClick={handleDrawerOpen}>
        <MenuIcon style={{ color: "white" }} />
      </IconButton>
      <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
        <div
          style={{
            width: "250px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <List>
            <ListItemButton
              onClick={() => {
                navigate({
                  pathname: "/dashboard",
                });
              }}
            >
              <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                navigate({
                  pathname: "/press-release",
                });
              }}
            >
              <ListItemText primary="Press Release Write Up" />
            </ListItemButton>
          </List>
          <List>
            <ListItemButton
              style={{ marginTop: "auto" }}
              onClick={() => {
                // clears storage = log outs
                localStorage.clear();
                navigate({
                  pathname: "/",
                });
                window.location.reload();
              }}
            >
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </div>
      </Drawer>
    </>
  );
}

export default DrawerMenu;
