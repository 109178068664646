import React, { useEffect, useState } from "react";

//mui
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Unstable_Grid2";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

// import IconButton from "@mui/material/IconButton";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import useMediaQuery from "@mui/material/useMediaQuery";
import spoelogo from "../images/spoelogo.png";

// open ai
import { queryQuestion } from "../api/openAi";
import { querySave } from "../api/query";

// token tracker
import { updateUser } from "./../api/user";

// stripe
import StripeContainer from "./../components/stripeContainer";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    submitButton: {
      // purple
      main: "#379FFF",
      contrastText: "#fff",
    },
  },
});

const initialState = {
  messageList: [],
  userInput: "",
};

const exampleArray = [
  {
    text: "Write a press release for a company that supplies commercial furniture called Sandler Seating. Their target audience are commercial interior designers. ",
  },
  {
    text: `Write an expert press release with the following information: 

New commercial furniture named “Oculus” 
Target — for interior designers. 
Launch — January 15, 2023. Ends 2/15/2023
Who: Sandler Seating
Where: Sandler Seating provide commercial furniture to interior designers. 
Why: something interesting about the item specifically
`,
  },
];

const capabalitiesArray = [
  {
    text: "This is still in beta, so some of the responses may not be accurate.",
  },
  {
    text: `If the answers are inaccurate, please try writing your questions in more detail like the examples above.`,
  },
];

function MarketingConsultancy(props) {
  // state
  const [values, setValues] = useState(initialState);
  const [openAiResponse, setOpenAiResponse] = useState("");
  const [responseArray, setResponseArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState([]);
  const [saved, setSaved] = useState(false);
  // setSaved but for useEffect
  const [useSaved, setUseSaved] = useState(false);
  // token from request
  const [currToken, setCurrToken] = useState(0);
  // total consumed tokens state gets from localstorage
  // const [consumedTokens, setConsumedTokens] = useState(0);
  // const [consumedTokensPrice, setConsumedTokensPrice] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  // modal
  const [open, setOpen] = useState(false);
  const [paying, setPaying] = useState(false);
  const [due, setDue] = useState(0);
  // from child
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  // req cap
  const basic_cap = 15;
  const permium_cap = 600;

  // local storage
  const user = JSON.parse(localStorage.getItem("user-data"));
  const user_id = user ? user.id : "";
  const user_email = user ? user.email : "";
  const user_reqCounter = user ? user.requestCounter : "";
  const user_isPremium = user ? user.isPremium : "";

  // prices
  const annual_price = 499;
  const monthly_price = 49;

  // media query
  const smallScreen = useMediaQuery("(max-width:600px)");

  // modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: smallScreen ? "90%" : 700,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  // maxtokens
  // const per_request_cost = 0.02; // USD
  // const cost_per_k_tokens = 1000;

  // function calculateMaxTokens() {
  //   const max_tokens = (consumedTokens / cost_per_k_tokens) * per_request_cost;
  //   setConsumedTokensPrice(max_tokens);
  // }

  // useEffect(() => {
  //   // gets token counter
  //   const tokenCounter = user ? user.tokenCounter : "";
  //   console.log(`token counter from db ${tokenCounter}`);
  //   setConsumedTokens(tokenCounter);

  //   // eslint-disable-next-line
  // }, []);

  // sets tokens consumed when chanes are madee to consumed tokens
  // useEffect(() => {
  //   calculateMaxTokens();
  //   // eslint-disable-next-line
  // }, [consumedTokens, currToken]);

  // updates local storage tokenCounter
  useEffect(() => {
    if (useSaved) {
      const updatedUser = {
        ...user,
        tokenCounter: user.tokenCounter + currToken,
        requestCounter: user.requestCounter + 1,
      };
      localStorage.setItem("user-data", JSON.stringify(updatedUser));
      // updates user
      handleUpdateUser();
      // set to false to not run again unless saved
      setUseSaved(false);
    }

    // only saves when document is saved
    // eslint-disable-next-line
  }, [useSaved]);

  // scroll to bottom
  useEffect(() => {
    const element = document.getElementById("bottom");
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [openAiResponse, values.messageList]);

  // handles changes
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const handleQueryCallback = (inputValues) => {
    const data = {
      // do not show on chat, only added behind the scenes
      prompt: `You are an expert press release writer. ${values.userInput}`,
      temperature: 1,
      max_tokens: 500,
      top_p: 1,
      best_of: 2,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };
    var headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    queryQuestion(data, headers, (callback) => {
      // to get token callback.data.usage.total_tokens
      // token pricing is $0.0200  / 1K tokens

      // set loading to true
      setLoading(true);
      // set array if try
      if (callback.status === 200) {
        // when response from server is good we show saved button
        setSaved(true);
        setUseSaved(true);
        setOpenAiResponse(callback.data.choices[0]);
        setResponseArray([...responseArray, callback.data.choices[0].text]);
        const newObject = {
          user_input: values.userInput,
          openai_response: callback.data.choices[0].text,
        };
        setQuery([...query, newObject]);
        setCurrToken(callback.data.usage.total_tokens);
        // update token called here
        handleQuerySave();
      } else {
        console.log(callback);
      }
      // set loading false
      setLoading(false);
    });
  };

  // updates token
  const handleUpdateUser = async () => {
    const id = user.id;
    const data = {
      id: id,
      tokenCounter: currToken,
      // always set to 1 as it increments by 1
      requestCounter: 1,
    };

    const headers = { "Content-Type": "application/json" };

    try {
      const response = await updateUser(data, headers);
      console.log("updated");
      console.log(response);
      //   setConsumedTokens(response.data.tokenCounter);
      setRequestCount(response.data.requestCounter);
    } catch {
      console.log("Something went wrong, token counter not updated");
    }
  };

  const handleUserSubscription = async () => {
    const id = user.id;
    const data = {
      id: id,
      isPremium: paymentSuccess,
    };

    const headers = { "Content-Type": "application/json" };

    try {
      const response = await updateUser(data, headers);
      console.log("updated");
      console.log(response);
    } catch {
      console.log("Something went wrong, token counter not updated");
    }
  };

  useEffect(() => {
    if (paymentSuccess === true) {
      console.log("User Upgraded to premium");
      const updatePremium = {
        ...user,
        isPremium: paymentSuccess,
      };

      // call api to set user to premiuim sub
      handleUserSubscription();
      // set item on localstorage
      localStorage.setItem("user-data", JSON.stringify(updatePremium));
      // reload page after half a second
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    // eslint-disable-next-line
  }, [paymentSuccess]);

  // useEffect(() => {
  //   // updates token counter when openai responses
  //   if (openAiResponse !== "") {
  //     handleUpdateUser();
  //   }

  //   // eslint-disable-next-line
  // }, [openAiResponse]);

  const handleQuerySave = async () => {
    const data = {
      query: query,
    };

    const headers = {
      "Content-Type": "application/json",
      id: user_id,
      email: user_email,
    };

    try {
      const response = await querySave(data, headers);
      console.log(response);
      if (response.status === 200) {
        setSaved((prevSaved) => !prevSaved);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // set payment sucess top true
  const handlePayment = (dataFromChild) => {
    setPaymentSuccess(dataFromChild);
  };

  // typing effect
  // useEffect(() => {
  //   console.log(responseArray[responseArray.length - 1]);
  // }, [responseArray]);

  // modal controls
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPaying(false);
  };

  return (
    <>
      <Grid
        container
        height="100vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#DBDADB"
      >
        <Grid xs={12} md={9}>
          <div className="press-header-container">
            <div className="chat-box-header-container">
              {" "}
              <h2>Press Release Write Up</h2>
              {user_isPremium ? (
                // for paid
                <Tooltip title="Requests used" placement="top" arrow>
                  <span className="premium">
                    {`${
                      requestCount ? requestCount : user_reqCounter
                    } / ${permium_cap}`}{" "}
                    Premium
                  </span>
                </Tooltip>
              ) : (
                // for free version
                <Tooltip title="Requests used" placement="top" arrow>
                  <span className="basic" onClick={handleOpen}>
                    {`${
                      requestCount ? requestCount : user_reqCounter
                    } / ${basic_cap}`}{" "}
                    Basic
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="save-button">
              {/* checks if saved if it is remove save button to prevent spam saves */}
              {saved ? (
                <>
                  {/* check if reponse is greater than 0 */}
                  {responseArray.length > 0 ? (
                    // show save if array is not empty
                    <Button onClick={handleQuerySave}>
                      <SaveIcon />
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {responseArray.length > 0 ? (
                    <div className="saved-reminder">
                      <h4>Queries Saved!</h4>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="chatbox-container">
            <div className="chatbox">
              {values.messageList.length >= 1 ? (
                <></>
              ) : (
                // suggestions
                <div className="suggestions">
                  <div className="example-container">
                    <LightbulbIcon fontSize="large" />
                    <div className="header-text-examples">Examples</div>
                    {exampleArray.map((item, index) => (
                      <div
                        key={index}
                        className="example-box"
                        onClick={() => {
                          console.log("clikced");
                          setValues({
                            ...values,
                            userInput: item.text,
                          });
                        }}
                      >
                        <p>{item.text}</p>
                      </div>
                    ))}
                  </div>
                  {/* limitations */}
                  <div className="example-container">
                    <div className="example-container">
                      <ReportGmailerrorredIcon fontSize="large" />
                      <div className="header-text-examples">Limitations</div>
                      {capabalitiesArray.map((item, index) => (
                        <div className="limitations-box" key={index}>
                          <p>{item.text}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {values.messageList.map((item, index) => (
                <div key={index}>
                  <div className="message-container">
                    <div className="message chat-message">
                      <p>{item.message}</p>
                    </div>
                    <div className="user-image-container">
                      <img src={spoelogo} alt="user" className="user-image" />
                    </div>
                  </div>
                  {responseArray[index] ? (
                    <div className="reply-container">
                      <div className="spoe-image-container">
                        <img src={spoelogo} alt="user" className="user-image" />
                      </div>
                      <div className="reply chat-message">
                        <p>{responseArray[index]}</p>{" "}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}

              <div id="bottom" />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                // set chat object
                const newQuery = {
                  message: values.userInput,
                };

                // update state of query sent
                setValues({
                  ...values,
                  messageList: [...values.messageList, newQuery],
                  userInput: "",
                });

                // send request o open ai
                handleQueryCallback();
              }}
            >
              <div className="message-field">
                <TextField
                  size="large"
                  multiline
                  maxRows={3}
                  fullWidth
                  inputProps={{
                    style: { width: smallScreen ? "70vw" : "90%" },
                  }}
                  value={values.userInput || ""}
                  onChange={handleChange("userInput")}
                  disabled={loading ? true : false}
                />
                <ThemeProvider theme={theme}>
                  <Button
                    // className={
                    //   !values.userInput === ""
                    //     ? "submit-button openai"
                    //     : "submit-button openai disblaed"
                    // }
                    className="submit-button openai"
                    size="small"
                    // color="submitButton"
                    type="submit"
                    disabled={values.userInput === ""}
                  >
                    <SendIcon />
                  </Button>
                </ThemeProvider>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
      {/* Payment Modeal */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {/* add this please */}
          {/* should have a lock icon and say something like "secure payment via Stripe" */}
          {/* switch to stripe when user has clicked continue */}
          {paying ? (
            <div>
              <Typography variant="subtitle1">
                {paymentSuccess === false ? `Amount due: £${due}` : ""}
              </Typography>
              <StripeContainer amount_due={due} handleData={handlePayment} />{" "}
              <div className="back-button">
                {paymentSuccess === false ? (
                  <Typography
                    variant="overline"
                    onClick={() => {
                      setPaying(false);
                    }}
                  >
                    Back
                  </Typography>
                ) : (
                  <Typography
                    variant="overline"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Back
                  </Typography>
                )}
              </div>
            </div>
          ) : (
            <div className="plans-modal">
              {/* headers */}
              <div className="plans-modal-header">
                <Typography variant="h6" gutterBottom>
                  Upgrade to <span className="prem">PREMIUM</span>
                </Typography>
                <p>
                  Get up to <span>600</span> requests per month with our premium{" "}
                  <span>Press Release Writer</span>
                </p>
              </div>
              {/* body */}
              <div className="plans-body">
                {/* monthly */}
                <div className="monthly">
                  <Typography variant="subtitle1" gutterBottom>
                    Monthly
                  </Typography>
                  <Typography variant="h3">£49.99</Typography>
                  <Typography variant="overline" gutterBottom>
                    PER MONTH
                  </Typography>
                  <br />
                  <Button
                    className="conitnue-button"
                    variant="contained"
                    onClick={() => {
                      console.log("Pressed Monthly");
                      setPaying(true);
                      setDue(monthly_price);
                    }}
                  >
                    Continue
                  </Button>
                </div>
                {/* annual */}
                <div className="anually">
                  <Typography variant="subtitle1" gutterBottom>
                    Annually
                  </Typography>
                  <Typography variant="h3">£499.99</Typography>
                  <Typography variant="overline" gutterBottom>
                    Per year
                  </Typography>{" "}
                  <br />
                  <Typography variant="overline" className="bonus" gutterBottom>
                    Free 2 months!
                  </Typography>
                  <br />
                  <Button
                    className="conitnue-button"
                    variant="contained"
                    onClick={() => {
                      console.log("Pressed annual");
                      setPaying(true);
                      setDue(annual_price);
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
              <div className="close-button-modal">
                <Typography variant="overline" onClick={handleClose}>
                  Close
                </Typography>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default MarketingConsultancy;
