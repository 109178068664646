import React, { useState, useEffect } from "react";

// route to get data
import { useSearchParams, useNavigate } from "react-router-dom";

// mui
import Grid from "@mui/material/Unstable_Grid2";

// apex charts
import Chart from "react-apexcharts";

// logo
import spoe_logo from "./../images/SPOE-logo-bdg-2000px.png";

// callendly
import { InlineWidget } from "react-calendly";
import { TextField, Typography, Button } from "@mui/material";

// api
import { updateDomainAuthUrl } from "../api/domainAuth";

// move to a component next version
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function DomainAuth(props) {
  // eslint-disable-next-line
  const [options, setOptions] = useState({
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: 70,
        endAngle: 290,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          max: 100,
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: true,
            color: "#45525D",
            fontSize: "16px",
            offsetY: -60,
          },
          value: {
            offsetY: -20,
            fontSize: "32px",
          },
        },
      },
    },
    fill: {
      type: ["solid", "gradient"],
      colors: ["#379FFF"],
      gradient: {
        shade: "light",
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0],
      },
    },
    labels: [""],
  });
  const [email, setEmail] = useState("");
  const [updated, setUpdated] = useState(false);

  // handles email change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  //update domain Auth Saved to add email
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await updateDomainAuthUrl(
        queryId,
        { email: email },
        { Authorization: `Bearer ${process.env.TOKEN}` }
      );
      setUpdated(true);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // get window size
  // eslint-disable-next-line
  const { height, width } = useWindowDimensions();

  //get query params
  const [searchparams] = useSearchParams();

  // console.log(searchparams.get("daScore"));

  // navigate
  const navigate = useNavigate();

  // parameters set as consts
  const daScore = searchparams.get("daScore");
  const title = searchparams.get("title");
  const queryId = searchparams.get("queryId");

  // eslint-disable-next-line
  // const title =
  //   searchparams.get("title").charAt(0).toUpperCase() +
  //   searchparams.get("title").slice(1);

  // if user types da without data
  const returnToHome = (daScore, title) => {
    navigate({
      pathname: "/",
    });
  };

  useEffect(() => {
    // console.log(`The DA score is ${daScore}`);
    // return user to homepage if he or she jsut types /da on the url
    if (daScore === null) {
      returnToHome();
    } // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      height="100vh"
      justifyContent="center"
      backgroundColor="#FFFFF"
      style={{
        marginTop: "10vh",
      }}
    >
      <Grid xs={10} md={8}>
        <div className="logo-container">
          <img src={spoe_logo} className="large-logo" alt="logo" />
        </div>
        <div className="tagline_hero da">
          <h1>Domain Rating</h1>
        </div>
        <div className="chart-container">
          <Chart
            options={options}
            series={[daScore]}
            type="radialBar"
            width={width < 800 ? 300 : 500}
            height={400}
          />
        </div>
        <div className="client-url">
          <h1>{title}</h1>
        </div>
        <div className="domain-rating-desc">
          <p>
            For your industry,
            <span> Google rates your website {daScore}/100</span>
          </p>
        </div>
        <div className="callendly-section">
          <p className="know-more-text">
            If you want to know how this is calculated and how you can improve
            this score so you rank higher on Google, please book a free call
            with us below:
          </p>

          {updated ? (
            <>
              <Typography variant="h6">You'll hear from us soon!</Typography>
            </>
          ) : (
            <>
              <Typography> If your score changes we'll email you!</Typography>
              <div>
                <form
                  className="update-email-domain-auth"
                  onSubmit={handleSubmit}
                >
                  <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </form>
              </div>
            </>
          )}

          <InlineWidget
            styles={{
              height: "1000px",
              width: "100%",
            }}
            url="https://calendly.com/secondpairofeyes/15min"
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default DomainAuth;
