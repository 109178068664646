import React, { useState, useEffect } from "react";

export function TypingEffect({ text, typingDelay }) {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let currentCharIndex = 0;
    const intervalId = setInterval(() => {
      const charToAdd = text.charAt(currentCharIndex);
      setDisplayText((displayText) => displayText + charToAdd);
      currentCharIndex++;
      if (currentCharIndex === text.length) {
        clearInterval(intervalId);
      }
    }, typingDelay);
    return () => clearInterval(intervalId);
  }, [text, typingDelay]);

  return <span>{displayText}</span>;
}

export function TypingEffectArray({ switchTexts }) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    let currentCharIndex = 0;
    let isDeleting = false;
    let typingTimeout;

    const startTyping = () => {
      const currentText = switchTexts[currentTextIndex].text;

      setDisplayText((displayText) => {
        if (currentCharIndex < currentText.length && !isDeleting) {
          return currentText.substring(0, currentCharIndex + 1);
        } else {
          isDeleting = true;
          return currentText.substring(0, currentCharIndex);
        }
      });

      if (isDeleting) {
        currentCharIndex--;
      } else {
        currentCharIndex++;
      }

      if (currentCharIndex === currentText.length + 1) {
        isDeleting = true;
        typingTimeout = setTimeout(() => {
          setShowCursor(false);
          setCurrentTextIndex(
            (currentTextIndex) => (currentTextIndex + 1) % switchTexts.length
          );
        }, 2000);
      } else if (currentCharIndex === -1) {
        isDeleting = false;
        typingTimeout = setTimeout(() => {
          setShowCursor(true);
          setCurrentTextIndex(
            (currentTextIndex) => (currentTextIndex + 1) % switchTexts.length
          );
        }, 500);
      } else {
        setShowCursor(true);
      }

      typingTimeout = setTimeout(startTyping, isDeleting ? 75 : 150);
    };

    startTyping();

    return () => clearTimeout(typingTimeout);
  }, [currentTextIndex, switchTexts]);

  return (
    <span>
      {displayText}
      {showCursor && <span className="cursor">|</span>}
    </span>
  );
}
