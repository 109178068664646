import React, { useState } from "react";

// mui
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// logo
import spoe_logo from "./../images/spoelogo.png";

// validator
//import isEmail from "validator/lib/isEmail";

// redux
import { useDispatch } from "react-redux";
import { collectUserData } from "../redux/reducer/userAuth";

// api
import { loginUser } from "../api/user";

// loader
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

// route for data passing / navigating
import { useNavigate } from "react-router-dom";

// import { checkUserAuth } from "../helpers/userAuthHelper";

const initialState = {
  email: "",
  password: "",
  errorMessage: "",
  showPassword: false,
  notEmail: false,
};

function LoginPage(props) {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  // get whatever value of this {} in userData reducer
  // const { email } = useSelector((state) => state.userData);
  // const { token } = useSelector((state) => state.userData);
  // const { first_name } = useSelector((state) => state.userData);

  // navigate
  const navigate = useNavigate();

  // dispatch calls actions
  const dispatch = useDispatch();

  // handles changes
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value.trim(),
    });
  };

  // show password
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginCallback = async (inputValues) => {
    // set loading to true
    setLoading((prevState) => (prevState = true));
    const data = {
      email: inputValues.email,
      password: inputValues.password,
    };

    // content type has to be json
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await loginUser(data, headers);
      console.log(response.data);
      const userData = response.data;
      const userInfo = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        token: userData.token,
        id: userData.id,
        tokenCounter: userData.tokenCounter,
        requestCounter: userData.requestCounter,
        isPremium: userData.isPremium,
        website_url: userData.website_url,
        company_operation: userData.company_operation,
        company_selling_points: userData.company_selling_points,
        company_ideal_customer: userData.company_ideal_customer,
        company_goal: userData.company_goal,
        previous_campaigns: userData.previous_campaigns,
        current_marketing_efforts: userData.current_marketing_efforts,
        company_name: userData.company_name,
      };

      // dispatch to redux
      dispatch(collectUserData(userInfo));
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("user-data", JSON.stringify(userInfo));
      navigate({
        pathname: "/dashboard",
      });

      window.location.reload();
    } catch (error) {
      // wrong password
      console.log("Wrong");
      setValues((prevState) => ({
        ...prevState,
        errorMessage: "*Email or Password is Incorrect",
        password: "",
        isManualAuthenticated: false,
      }));
    } finally {
      setLoading((prevState) => (prevState = false));
    }
  };

  return (
    <>
      <Grid
        container
        height="100vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#FFFFF"
      >
        {loading ? (
          <ClimbingBoxLoader
            color={"#379FFF"}
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
            size={20}
          />
        ) : (
          <Grid xs={12} md={4} className="login-grid">
            <div className="logo-container">
              <img
                src={spoe_logo}
                alt="logo"
                className="logo-spoe-click"
                onClick={() => {
                  navigate({
                    pathname: "/",
                  });
                }}
              />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const inputValues = {
                  email: values.email,
                  password: values.password,
                };
                handleLoginCallback(inputValues);
              }}
            >
              <div className="login-form">
                {/* email */}
                <div className="login-email" style={{ width: "100%" }}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    autoCapitalize="off"
                    style={{ marginTop: "10px" }}
                  >
                    Email
                  </Typography>
                  <TextField
                    id="email-input-login"
                    required
                    autoCapitalize="off"
                    error={values.notEmail ? true : false}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: "2px" }}
                    placeholder="youremail@spoe.co"
                    onChange={handleChange("email")}
                    value={values.email || ""}
                  />
                </div>
                {/* password */}
                <div className="login-password" style={{ width: "100%" }}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ marginTop: "10px" }}
                  >
                    Password
                  </Typography>
                  <TextField
                    id="password-input-login"
                    type={values.showPassword ? "text" : "password"}
                    required
                    autoComplete="off"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    error={values.errorMessage !== ""}
                    placeholder="*******"
                    style={{ marginTop: "2px" }}
                    onChange={handleChange("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={values.password || ""}
                  />
                </div>
                {/* forget password */}
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ color: "#A43532" }}
                >
                  {values.errorMessage}
                </Typography>
                <div className="submit-button">
                  <Button
                    variant="contained"
                    style={{
                      marginTop: "20px",
                      paddingTop: "8px",
                      background: "#379FFF",
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
            <div
              style={{
                textAlign: "center",
                marginTop: "2em",
                color: "#379FFF",
              }}
              onClick={() => {
                // move forget password page
                navigate({
                  pathname: "/forget",
                });
              }}
            >
              <span type="submit" className="forgot-password-text">
                Forgot Password?
              </span>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default LoginPage;
