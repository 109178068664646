import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { TextField, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

// api
import { queryQuestion } from "./../api/openAi";

// loader
import BounceLoader from "react-spinners/BounceLoader";
import DrawerMenu from "../components/DrawerMenu";

const initialState = {
  purpose: "",
  target_audience: "",
  key_message: "",
  supporting_info: "",
  contact: "",
};

const user = JSON.parse(localStorage.getItem("user-data"));
const user_name = user ? user.first_name : "";
const company_name = user ? user.company_name : "";

function PressRelease(props) {
  const [values, setValues] = useState(initialState);
  const [pressRelease, setPressRelease] = useState("");
  const [loading, setLoading] = useState(false);
  const smallScreen = useMediaQuery("(max-width:600px)");

  const generatePressRelease = () => {
    const data = {
      // do not show on chat, only added behind the scenes
      prompt: `You are an expert press release writer.  Please write a press release, for ${company_name}. The purpose of this press relase is ${values.purpose}.  The target audience of this press release is ${values.target_audience}.  The key message of this press release is ${values.key_message}.  Addional supporting info are ${values.supporting_info}.  Finally, the contact info is ${values.contact}`,
      temperature: 1,
      max_tokens: 3000,
      top_p: 1,
      best_of: 2,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };
    var headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    queryQuestion(data, headers, (callback) => {
      try {
        if (callback.status === 200) {
          setPressRelease(callback.data.choices[0].text.replace("\n\n", "\n"));
          console.log(callback);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div className="press-content">
      {/* hidden on mobile */}
      <div className="header-content-text">
        <Typography variant="h4">Welcome, {user_name}</Typography>
        <Typography variant="h4">{company_name}</Typography>
      </div>
      {/* display on mobile */}
      {smallScreen ? (
        <div className="mobile-header">
          <Typography variant="caption">{company_name}'s AI</Typography>
          <DrawerMenu />
        </div>
      ) : (
        ""
      )}

      <div className="press-release-container">
        <div
          className={
            pressRelease !== "" ? "press-release-box wide" : "press-release-box"
          }
        >
          <div className="press-release-item">
            <Typography
              variant="subtitle1"
              onClick={() => {
                console.log(loading);
              }}
            >
              {loading === true
                ? "Writing your press release..."
                : pressRelease !== ""
                ? `${values.purpose}`
                : `Press Release Writer`}
            </Typography>
          </div>
          {loading === true ? (
            <BounceLoader
              color={"#379FFF"}
              aria-label="Loading Spinner"
              data-testid="loader"
              size={20}
            />
          ) : pressRelease !== "" ? (
            <p style={{ whiteSpace: "pre-line" }}>{pressRelease}</p>
          ) : (
            <form
              style={{ width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
                setLoading(true);
                generatePressRelease();
              }}
            >
              <div className="press-release-item">
                <TextField
                  label="Purpose"
                  name="purpose"
                  multiline
                  rows={2}
                  value={values.purpose}
                  onChange={handleChange}
                  fullWidth
                  required
                  placeholder="The reason for the Press Release"
                  //   InputProps={{ style: { fontSize: 10 } }}
                />
              </div>
              <div className="press-release-item">
                <TextField
                  label="Target Audience"
                  name="target_audience"
                  multiline
                  rows={2}
                  value={values.target_audience}
                  onChange={handleChange}
                  fullWidth
                  required
                  placeholder="Who the press release is intended for."
                  //   InputProps={{ style: { fontSize: 10 } }}
                />
              </div>
              <div className="press-release-item">
                <TextField
                  label="Key Message"
                  name="key_message"
                  multiline
                  rows={2}
                  value={values.key_message}
                  onChange={handleChange}
                  fullWidth
                  required
                  placeholder="Statement that summarizes the message."
                  //   InputProps={{ style: { fontSize: 10 } }}
                />
              </div>
              <div className="press-release-item">
                <TextField
                  label="Supporting Info"
                  name="supporting_info"
                  multiline
                  rows={2}
                  value={values.supporting_info}
                  onChange={handleChange}
                  fullWidth
                  required
                  placeholder="Provide details that can help support the key message."
                  //   InputProps={{ style: { fontSize: 10 } }}
                />
              </div>
              <div className="press-release-item">
                <TextField
                  label="Contact"
                  name="contact"
                  multiline
                  rows={2}
                  value={values.contact}
                  onChange={handleChange}
                  fullWidth
                  required
                  placeholder="Contact information from the company"
                  //   InputProps={{ style: { fontSize: 10 } }}
                />
              </div>
              <div className="press-release-item">
                <Button
                  style={{ backgroundColor: "#F16334", color: "white" }}
                  type="submit"
                  fullWidth
                  size="large"
                >
                  Create Press Release
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default PressRelease;
