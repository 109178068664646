import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./reducer/userAuth";

export default configureStore({
  // add here
  reducer: {
    userData: userReducer,
  },
});
