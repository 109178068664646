import React, { useState } from "react";

// mui
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// logo
import spoe_logo from "./../images/spoelogo.png";

// validator
import isEmail from "validator/lib/isEmail";
import isStrongPassword from "validator/lib/isStrongPassword";

// api
import { registerUser } from "../api/user";

import { useDispatch } from "react-redux";
import { collectUserData } from "../redux/reducer/userAuth";

// router
import { useNavigate } from "react-router-dom";

const initialState = {
  email: "",
  password: "",
  retype_password: "",
  errorMessage: "",
  first_name: "",
  last_name: "",
  company_name: "",
  showPassword: false,
  showRePassword: false,
  notEmail: false,
  notStrongPass: false,
  passwordMatch: false,
  emailInUse: "",
};

function SignupPage(props) {
  const [values, setValues] = useState(initialState);

  // dispatch calls actions
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // handles changes
  const handleChange = (prop) => (event) => {
    if (prop === "email") {
      setValues({
        ...values,
        [prop]: event.target.value.trim(),
      });
    } else {
      setValues({
        ...values,
        [prop]: event.target.value,
      });
    }
  };

  // show password
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  // show retyped password
  const handleClickShowRePassword = () => {
    setValues({ ...values, showRePassword: !values.showRePassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // clears state
  // const clearState = () => {
  //   setValues({ ...initialState });
  //   console.log("clear");
  // };

  // handle register req
  const handleRegisterCallback = async (inputValues) => {
    // set loading to true
    // set data
    const data = {
      email: inputValues.email,
      password: inputValues.password,
      first_name: inputValues.first_name,
      last_name: inputValues.last_name,
      company_name: inputValues.company_name,
    };
    // content type headers
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await registerUser(data, headers);
      console.log(response);
      const userData = response.data;
      console.log(userData);
      const userInfo = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        token: userData.token,
        id: userData.id,
        tokenCounter: userData.tokenCounter,
        requestCounter: userData.requestCounter,
        isPremium: userData.isPremium,
      };

      // disptach to redux
      dispatch(collectUserData(userInfo));
      // add to local storage
      localStorage.setItem("token", JSON.stringify(response.data.token));
      localStorage.setItem("user-data", JSON.stringify(userInfo));
      navigate({
        pathname: "/",
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
      setValues({ ...values, emailInUse: data.error });
    }
  };

  return (
    <>
      <Grid
        container
        height="110vh"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#FFFFF"
        style={{ marginTop: "10vh", marginBottom: "10vh" }}
      >
        <Grid xs={12} md={4} className="register-grid">
          <div className="logo-container">
            <img
              src={spoe_logo}
              alt="logo"
              className="logo-spoe-click"
              onClick={() => {
                navigate({
                  pathname: "/",
                });
              }}
            />
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              // checks if it is indeed an email
              if (!isEmail(values.email)) {
                setValues({ ...values, notEmail: !values.notEmail });
                // return console.log("Not valid email");
              }
              // checks if password is strong
              if (!isStrongPassword(values.password)) {
                setValues({ ...values, notStrongPass: !values.notStrongPass });
                // return console.log("Not strong password");
              }
              //checks if passwords match
              if (values.password !== values.retype_password) {
                setValues({ ...values, passwordMatch: !values.passwordMatch });
                return console.log("Not a match");
              }

              const inputValues = {
                email: values.email,
                password: values.password,
                first_name: values.first_name,
                last_name: values.last_name,
                company_name: values.company_name,
              };
              // send to redux
              handleRegisterCallback(inputValues);

              // clearState();
            }}
          >
            <div className="login-form">
              {/* email */}
              <div className="register-email" style={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "10px" }}
                >
                  Email
                </Typography>
                <TextField
                  id="email-input-login"
                  required
                  autoCapitalize="off"
                  error={values.notEmail || values.emailInUse ? true : false}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: "2px" }}
                  placeholder="youremail@spoe.co"
                  onChange={handleChange("email")}
                  value={values.email || ""}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ color: "#A43532" }}
                >
                  {!values.notEmail ? "" : "Not valid email"}
                  {!values.emailInUse ? "" : "Email already in use"}
                </Typography>
              </div>
              {/* first name */}
              <div className="register-first-name" style={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "10px" }}
                >
                  First Name
                </Typography>
                <TextField
                  id="firstname-input-register"
                  required
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: "2px" }}
                  placeholder="John"
                  onChange={handleChange("first_name")}
                  value={values.first_name || ""}
                />
              </div>
              {/* last name */}
              <div className="register-last-name" style={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "10px" }}
                >
                  Last Name
                </Typography>
                <TextField
                  id="lastname-input-register"
                  required
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: "2px" }}
                  placeholder="Doe"
                  onChange={handleChange("last_name")}
                  value={values.last_name || ""}
                />
              </div>
              {/* company name */}
              <div className="register-company-name" style={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "10px" }}
                >
                  Company Name
                </Typography>
                <TextField
                  id="company-input-register"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: "2px" }}
                  placeholder="Second Pair Of Eyes"
                  onChange={handleChange("company_name")}
                  value={values.company_name || ""}
                />
              </div>
              {/* password */}
              <div className="register-password" style={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "10px" }}
                >
                  Password
                </Typography>
                <TextField
                  id="password-input-register"
                  type={values.showPassword ? "text" : "password"}
                  required
                  error={values.notStrongPass ? true : false}
                  helperText={
                    values.notStrongPass ? "Password not strong enough" : ""
                  }
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  placeholder="*******"
                  style={{ marginTop: "2px" }}
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={values.password || ""}
                />
              </div>
              {/* Retype password */}
              <div className="register-password" style={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ marginTop: "10px" }}
                >
                  Retype Password
                </Typography>
                <TextField
                  id="re-password-input-register"
                  type={values.showRePassword ? "text" : "password"}
                  error={values.passwordMatch ? true : false}
                  helperText={
                    values.passwordMatch ? "Password does not match" : ""
                  }
                  required
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  placeholder="*******"
                  style={{ marginTop: "2px" }}
                  onChange={handleChange("retype_password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowRePassword}
                          edge="end"
                        >
                          {values.showRePassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={values.retype_password || ""}
                />
              </div>
              <div className="submit-button">
                <Button
                  variant="contained"
                  style={{
                    marginTop: "20px",
                    paddingTop: "8px",
                    background: "#379FFF",
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default SignupPage;
