import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
// import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
// import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
// import IconButton from "@mui/material/IconButton";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { queryQuestion } from "./../api/openAi";
import useMediaQuery from "@mui/material/useMediaQuery";
// import MenuIcon from "@mui/icons-material/Menu";
// import IconButton from "@mui/material/IconButton";

import DrawerMenu from "../components/DrawerMenu";

function Dashboard(props) {
  const user = JSON.parse(localStorage.getItem("user-data"));
  const user_name = user ? user.first_name : "";
  const company_name = user ? user.company_name : "";
  const company_operation = user ? user.company_operation : "";
  const company_ideal_customer = user ? user.company_ideal_customer : "";
  const current_marketing_efforts = user ? user.current_marketing_efforts : "";
  const smallScreen = useMediaQuery("(max-width:600px)");

  const storedDailyTip = localStorage.getItem("dailyMarketingTip");
  const [dailyTip, setDailyTip] = useState(
    storedDailyTip || "No tip available"
  );

  const generateDailyMarketTips = async () => {
    const lastExecutionDate = localStorage.getItem("lastExecutionDate");
    const today = new Date().toISOString().split("T")[0];

    // Check if the function has already been executed today
    if (lastExecutionDate === today) {
      console.log("Daily tip is now available");
      return;
    }

    const data = {
      // do not show on chat, only added behind the scenes
      prompt: `Can you provide a marketing strategy that would effectively reach our target audience given our current marketing efforts and company operations? Our target audience is ${company_ideal_customer}, our company operates in ${company_operation}, and we are currently using ${current_marketing_efforts}.  Have a maximum of 2 sentences`,
      temperature: 1,
      max_tokens: 1000,
      top_p: 1,
      best_of: 2,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["###"],
    };
    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    };

    try {
      queryQuestion(data, headers, (response) => {
        console.log(response);

        const dailyMarketingTip = response.data.choices[0].text;

        // Store the daily tip and current date in localStorage
        localStorage.setItem("dailyMarketingTip", dailyMarketingTip);
        localStorage.setItem("lastExecutionDate", today);

        // Update state with the daily tip
        setDailyTip(dailyMarketingTip);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    generateDailyMarketTips();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard-content">
      <div className="header-content">
        {/* hidden on mobile */}
        <div className="header-content-text">
          <Typography variant="h4">Welcome, {user_name}</Typography>
          <Typography variant="h4">{company_name}</Typography>
        </div>
        {/* display on mobile */}
        {smallScreen ? (
          <div className="mobile-header">
            <Typography variant="caption">{company_name}'s AI</Typography>
            <DrawerMenu />
          </div>
        ) : (
          ""
        )}

        <div className="ai-trainer">
          <div className="trainer-header">
            <div className="ai-trainer-icons">
              <ViewInArIcon />
            </div>
            <div className="ai-trainer-header-title">
              <Typography variant="h6" className="main-title">
                Your Daily Marketing Tip!
              </Typography>
              {/* <Typography variant="subtitle2" className="sub-title">
                Help your AI learn more about Sandler Seating by giving feedback
                below
              </Typography> */}
            </div>
          </div>
          <div className="divider" />
          <div className="trainer-header">
            <div className="ai-trainer-body">
              <div className="details">
                {/* <Typography variant="h5" className="main-title">
                  A short summary of your Business:
                </Typography> */}
                <Typography variant="subtitle2" className="sub-title">
                  {dailyTip}
                </Typography>
              </div>
              {/* <div className="user-response">
                <IconButton
                  className="good-output"
                  onClick={() => {
                    console.log(dailyTip);
                  }}
                >
                  <ThumbUpOffAltOutlinedIcon />
                </IconButton>
                <IconButton
                  className="bad-output"
                  onClick={generateDailyMarketTips}
                >
                  <ThumbDownAltOutlinedIcon />
                </IconButton>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
