import React, { useEffect } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./paymentForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

export default function App(props) {
  const amount_due = props.amount_due;
  //   const options = {
  //     // passing the client secret obtained from the server
  //     clientSecret: `${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
  //   };
  const handleDataFromChild2 = (dataFromChild2) => {
    props.handleData(dataFromChild2);
  };

  // for testing purposes
  useEffect(() => {
    console.log(amount_due);
  });

  return (
    <Elements stripe={stripePromise}>
      <PaymentForm amount_due={amount_due} handleData={handleDataFromChild2} />
    </Elements>
  );
}
