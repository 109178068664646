import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";

import spoelogo from "./../../images/spoelogo.png";

// navigation
import { useLocation, useNavigate } from "react-router-dom";

// helper
import { checkUserAuth } from "../../helpers/userAuthHelper";

export default function ButtonAppBar() {
  const [state, setState] = useState({
    isLoggedIn: false,
    isSmallScreen: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user-data"));
  const user_name = user ? user.first_name : "";
  const user_email = user ? user.email : "";

  const smallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const isAuth = checkUserAuth();

    if (!isAuth) {
      // if NO user is logged in
      setState((prevState) => ({
        ...prevState,
        isLoggedIn: checkUserAuth(),
      }));
    } else {
      // if a user is logged in
      setState((prevState) => ({
        ...prevState,
        isLoggedIn: checkUserAuth(),
      }));
    }
  }, [location.pathname]);

  return (
    <>
      {state.isLoggedIn ? (
        // if user is logged in
        <AppBar position="fixed" elevation={0} style={{ background: "#FFFF" }}>
          <Grid container justifyContent="center" backgroundColor="#FFFFF">
            <Grid xs={smallScreen ? 12 : 10} md={8}>
              {/* for desktop */}
              {smallScreen ? (
                // for mobile
                <div className="header-contents user-true mobile">
                  <img
                    src={spoelogo}
                    alt="logo"
                    className="header-logo"
                    onClick={() => {
                      navigate({
                        pathname: "/",
                      });
                    }}
                  />
                  <Button
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isSmallScreen: !state.isSmallScreen,
                      }));
                      console.log(!state.isSmallScreen);
                    }}
                  >
                    <MenuIcon fontSize="large" />
                  </Button>
                  <Drawer
                    open={state.isSmallScreen}
                    id="drawer"
                    anchor="left"
                    onClose={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isSmallScreen: !state.isSmallScreen,
                      }));
                    }}
                  >
                    <div className="drawer-contents">
                      <div className="drawer-user">
                        <span className="user_name">{user_name}</span>
                        <span className="user_email">{user_email}</span>
                      </div>
                      <div className="drawer-list">
                        <Button
                          startIcon={<StickyNote2Icon />}
                          className="drawer-item"
                          onClick={() => {
                            navigate({
                              pathname: "/press-release-writeup",
                            });
                            setState((prevState) => ({
                              ...prevState,
                              isSmallScreen: !state.isSmallScreen,
                            }));
                            window.location.reload();
                          }}
                        >
                          <p>Press Release Writer</p>
                        </Button>
                        <Button
                          startIcon={<LogoutIcon />}
                          className="drawer-item"
                          onClick={() => {
                            // clears storage = log outs
                            localStorage.clear();
                            // move to home page after logging out
                            navigate({
                              pathname: "/",
                            });
                            // relaod page
                            window.location.reload();
                          }}
                        >
                          <p>Log Out</p>
                        </Button>
                      </div>
                    </div>
                  </Drawer>
                </div>
              ) : (
                // for desktop
                <div className="header-contents user-true">
                  <h2 style={{ color: "#293845" }}>Welcome, {user_name}</h2>

                  <div className="flex-center">
                    <Button
                      style={{ background: "#379FFF" }}
                      color="inherit"
                      onClick={() => {
                        navigate({
                          pathname: "/press-release-writeup",
                        });
                      }}
                    >
                      Press Release Writer
                    </Button>
                    <div
                      style={{
                        height: "2em",
                        margin: "1px 5px",
                      }}
                    />
                    <Button
                      style={{ background: "#379FFF" }}
                      color="inherit"
                      onClick={() => {
                        // clears storage = log outs
                        localStorage.clear();
                        // move to home page after logging out
                        navigate({
                          pathname: "/",
                        });
                        // relaod page
                        window.location.reload();
                      }}
                    >
                      Log Out
                    </Button>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </AppBar>
      ) : (
        <AppBar position="fixed" elevation={0} style={{ background: "#FFFF" }}>
          <Grid container justifyContent="center" backgroundColor="#FFFFF">
            <Grid xs={10} md={8}>
              <div className="header-contents">
                <Button
                  style={{ background: "#379FFF" }}
                  color="inherit"
                  onClick={() => {
                    navigate({
                      pathname: "/onboarding",
                    });
                    window.location.reload();
                  }}
                >
                  Register
                </Button>
                <div
                  style={{
                    borderLeft: "1px solid #379FFF",
                    height: "2em",
                    margin: "1px 5px",
                  }}
                />
                <Button
                  style={{ background: "#379FFF" }}
                  color="inherit"
                  onClick={() => {
                    navigate({
                      pathname: "/login",
                    });
                  }}
                >
                  Log In
                </Button>
              </div>
            </Grid>
          </Grid>
        </AppBar>
      )}
    </>
  );
}
