import axios from "axios";
import { rapidapi } from "./index";

// sample get urls
export async function getUrls(callback) {
  await axios
    .get(`${rapidapi}react-tabs-project`, callback, {
      "Content-Type": "multipart/form-data",
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

// get da based on url input
// params: url
export async function submitUrl(data, callback) {
  const options = {
    method: "GET",
    url: `${process.env.REACT_APP_RAPID_API_URL}`,
    params: { domain: data },
    headers: {
      "X-RapidAPI-Key": `${process.env.REACT_APP_RAPID_API_KEY}`,
      "X-RapidAPI-Host": `${process.env.REACT_APP_RAPID_API_HOST}`,
    },
  };

  await axios
    .request(options)
    .then(function (response) {
      callback(response);
      // console.log(response);
    })
    .catch(function (error) {
      // console.error(error);
    });
}
