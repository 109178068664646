import axios from "axios";
import { server } from "./index";

// Param: email, firstName, lastName, password, company name
export async function stripePay(data, headers) {
  try {
    const response = await axios.post(`${server}/stripe/customer/new`, data, {
      headers,
    });
    return response;
  } catch (error) {
    throw error.response.data;
  }
}

// export async function newCustomer(data, headers) {
//   try {
//     const response = await axios.post(`${server}/stripe/pay`, data, {
//       headers,
//     });
//     return response;
//   } catch (error) {
//     throw error.response.data;
//   }
// }
