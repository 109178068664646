import React from "react";
import Typography from "@mui/material/Typography";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import spoe_logo from "./../../images/spoelogo.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

function Sidebar(props) {
  const user = JSON.parse(localStorage.getItem("user-data"));
  const first_name = user ? user.first_name : "";
  const last_name = user ? user.last_name : "";

  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width:600px)");

  return (
    <>
      {smallScreen ? (
        ""
      ) : (
        <>
          {!user ? (
            ""
          ) : (
            <div className="sidebar">
              <div className="sidebar-content">
                <div className="upper-sidebar">
                  <div className="sidebar-header">
                    <Typography variant="h6" className="title">
                      {first_name[0]}
                      {user ? "" : last_name[0]}
                    </Typography>
                  </div>
                  <div className="sidebar-items">
                    <button
                      className={
                        window.location.pathname === "/dashboard"
                          ? "sidebar-button active"
                          : "sidebar-button"
                      }
                      onClick={() => {
                        navigate({
                          pathname: "/dashboard",
                        });
                      }}
                    >
                      {" "}
                      <HouseOutlinedIcon />
                      <Typography variant="caption">Home</Typography>
                    </button>
                    <button
                      className={
                        window.location.pathname === "/press-release"
                          ? "sidebar-button active"
                          : "sidebar-button"
                      }
                      onClick={() => {
                        navigate({
                          pathname: "/press-release",
                        });
                      }}
                    >
                      {" "}
                      <DriveFileRenameOutlineIcon />
                      <Typography variant="caption">Press Release</Typography>
                    </button>
                  </div>
                </div>
                <div className="lower-sidebar">
                  <div className="sidebar-items">
                    <button className="sidebar-button lower-button invite-button">
                      invite
                    </button>
                    <button className="sidebar-button lower-button">
                      <SettingsOutlinedIcon />
                    </button>
                    <button className="sidebar-button lower-button">
                      <NotificationsNoneOutlinedIcon />
                    </button>
                    <button
                      className="sidebar-button lower-button"
                      onClick={() => {
                        // clears storage = log outs
                        localStorage.clear();
                        navigate({
                          pathname: "/",
                        });
                        window.location.reload();
                      }}
                    >
                      <LogoutIcon />
                    </button>
                    <button className="sidebar-button spoe-button lower-button">
                      {" "}
                      <img src={spoe_logo} alt="logo" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Sidebar;
